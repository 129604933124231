import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet(`/rateSetting/search`, condition);
  },
  async import(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/rateSetting/import", bulkModel, header);
    });
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
