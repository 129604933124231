<template>
  <v-app>
    <v-navigation-drawer app clipped v-model="drawer" width="320" color="#F5EEF8">
      <v-container>
        <cafereo-menu v-if="isCafereoUser"></cafereo-menu>
        <ftrans-menu v-else-if="isFtransUser"></ftrans-menu>
        <maker-menu v-else-if="isMakerUser"></maker-menu>
        <vendor-menu v-else-if="isVendorUser"></vendor-menu>
      </v-container>
    </v-navigation-drawer>
    <v-app-bar color="#C39BD3" app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link :to="{ name: 'Dashboard' }">
          <span style="image-rendering: -webkit-optimize-contrast"
            ><v-img :src="require('../../assets/delphoi.png')" height="50" width="190" position="left"></v-img
          ></span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        absolute
        bottom
        v-model="notice_menu"
        min-width="550"
        max-width="550"
        :close-on-click="false"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" icon v-bind="attrs" v-on="on" @click="onNoticeButtonClick()">
            <v-badge overlap bordered color="red" :content="noticenum" :value="noticenum > 0">
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subtitle-1">
            <v-icon>mdi-bell</v-icon>あなた宛のお知らせ
            <v-spacer></v-spacer>
            <v-btn fab x-small outlined @click="onNoticeMenuClose()"><v-icon>mdi-close-thick</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-tabs v-model="notice_active_tab" grow>
              <v-tab href="#n_serious_tab">重要なお知らせ</v-tab>
              <v-tab href="#n_general_tab">その他更新など</v-tab>
            </v-tabs>
            <v-tabs-items v-model="notice_active_tab">
              <v-tab-item value="n_serious_tab">
                <v-row
                  ><v-col cols="9"
                    ><v-text-field
                      class="mx-4"
                      placeholder="お知らせの検索"
                      v-model="seriousSearchStr"
                    ></v-text-field></v-col
                  ><v-col
                    ><v-btn small class="mt-5" color="primary" @click="onReadedAll(notices.seriousNotifications)"
                      >全て既読</v-btn
                    ></v-col
                  ></v-row
                >
                <v-list dense>
                  <template v-for="(notice, index) in showSeriousNotifications">
                    <v-list-item :key="notice.notificationId" @click="onSeriousNoticeClick(index)">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="red" text-color="white" label x-small v-if="!notice.readedFlag">未読</v-chip>
                          <v-chip label x-small v-else>既読</v-chip>
                          {{ notice.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ notice.subtitle }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{ notice.contents }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text style="white-space: pre-wrap">{{
                          formatDate(notice.updateDatetime)
                        }}</v-list-item-action-text>
                        <v-btn x-small @click.stop="onSeriousNoticeReadMarkClick(index)" v-if="!notice.readedFlag"
                          >既読</v-btn
                        >
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="`_${index}`" v-if="index < showSeriousNotifications.length - 1"></v-divider>
                  </template>
                </v-list>
              </v-tab-item>
              <v-tab-item value="n_general_tab">
                <v-row
                  ><v-col cols="9"
                    ><v-text-field
                      class="mx-4"
                      placeholder="お知らせの検索"
                      v-model="generalSearchStr"
                    ></v-text-field></v-col
                  ><v-col
                    ><v-btn small class="mt-5" color="primary" @click="onReadedAll(notices.generalNotifications)"
                      >全て既読</v-btn
                    ></v-col
                  ></v-row
                >
                <v-list dense>
                  <template v-for="(notice, index) in showGeneralNotifications">
                    <v-list-item :key="notice.notificationId" @click="onGeneralNoticeClick(index)">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="red" text-color="white" label x-small v-if="!notice.readedFlag">未読</v-chip>
                          <v-chip label x-small v-else>既読</v-chip>
                          {{ notice.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ notice.subtitle }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{ notice.contents }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text style="white-space: pre-wrap">{{
                          formatDate(notice.updateDatetime)
                        }}</v-list-item-action-text>
                        <v-btn x-small @click.stop="onGeneralNoticeReadMarkClick(index)" v-if="!notice.readedFlag"
                          >既読</v-btn
                        >
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="`_${index}`" v-if="index < showGeneralNotifications.length - 1"></v-divider>
                  </template>
                </v-list>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-if="notice_active_tab === 'n_serious_tab'">
            {{ notise_page_size * (serious_notice_show_page - 1) + 1 }}
            -
            {{ notise_page_size * (serious_notice_show_page - 1) + showSeriousNotifications.length }} 件目 / 全
            {{ Object.keys(notices).length > 0 ? notices.seriousNotifications.length : 0 }} 件
            <v-spacer></v-spacer>
            <v-btn-toggle>
              <v-btn small @click="serious_notice_show_page--" :disabled="!hasprevseriousnotice">前</v-btn>
              <v-btn small @click="serious_notice_show_page++" :disabled="!hasnextseriousnotice">次</v-btn>
            </v-btn-toggle>
          </v-card-actions>
          <v-card-actions v-else>
            {{ notise_page_size * (general_notice_show_page - 1) + 1 }}
            -
            {{ notise_page_size * (general_notice_show_page - 1) + showGeneralNotifications.length }} 件目 / 全
            {{ Object.keys(notices).length > 0 ? notices.generalNotifications.length : 0 }} 件
            <v-spacer></v-spacer>
            <v-btn-toggle>
              <v-btn small @click="general_notice_show_page--" :disabled="!hasprevgeneralnotice">前</v-btn>
              <v-btn small @click="general_notice_show_page++" :disabled="!hasnextgeneralnotice">次</v-btn>
            </v-btn-toggle>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-dialog v-model="notice_dialog" max-width="560">
        <v-card v-if="notice_dialog">
          <v-card-title class="text-h6"><v-icon>mdi-new-box</v-icon>新着情報</v-card-title>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>タイトル</v-list-item-subtitle>
                {{ selected_notice.title }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>通知箇所</v-list-item-subtitle>
                {{ selected_notice.subtitle }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content style="white-space: pre-wrap">
                <v-list-item-subtitle>通知内容</v-list-item-subtitle>
                {{ selected_notice.contents }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>更新者</v-list-item-subtitle>
                {{ selected_notice.updateUser }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>更新日時</v-list-item-subtitle>
                {{ formatDate(selected_notice.updateDatetime) }}
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="notice_dialog = false"> 閉じる </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider class="mx-2" vertical></v-divider>
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip left :disabled="$vuetify.breakpoint.mdAndUp">
            <template v-slot:activator="{ on: tooltip }">
              <v-chip label color="secondary" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-avatar :left="$vuetify.breakpoint.mdAndUp">
                  <v-icon>mdi-account-circle</v-icon>
                </v-avatar>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  {{ username }}
                </template>
              </v-chip>
            </template>
            {{ username }}
          </v-tooltip>
        </template>
        <v-list dense>
          <v-list-item @click="profile_dialog = true">
            <v-list-item-icon><v-icon>mdi-badge-account-horizontal</v-icon></v-list-item-icon>
            <v-list-item-title>プロフィール</v-list-item-title>
          </v-list-item>
          <v-list-item @click="password_dialog = true">
            <v-list-item-icon><v-icon>mdi-key</v-icon></v-list-item-icon>
            <v-list-item-title>パスワード変更</v-list-item-title>
          </v-list-item>
          <v-list-item @click="notice_rules_dialog.show = true">
            <v-list-item-icon><v-icon>mdi-bullhorn</v-icon></v-list-item-icon>
            <v-list-item-title>通知設定</v-list-item-title>
          </v-list-item>
          <v-list-item @click="config_dialog = true">
            <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
            <v-list-item-title>個人設定</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="onLogoutClick">
            <v-list-item-icon><v-icon>mdi-logout-variant</v-icon></v-list-item-icon>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="profile_dialog" max-width="600" scrollable>
        <user-profile
          :user="loggedInUser"
          :show="profile_dialog"
          @close="profile_dialog = false"
          @password="password_dialog = true"
          v-if="profile_dialog"
        ></user-profile>
      </v-dialog>
      <v-dialog v-model="password_dialog" max-width="450">
        <password-change
          @submit="onPasswordCommit"
          @cancel="password_dialog = false"
          v-if="password_dialog"
        ></password-change>
      </v-dialog>
      <v-dialog
        v-model="notice_rules_dialog.show"
        :max-width="notice_rules_dialog.expand ? '100%' : '600px'"
        scrollable
      >
        <notification-setting
          @close="notice_rules_dialog.show = false"
          @submit="notice_rules_dialog.show = true"
          @expand="onNoticeRulesExpand"
          v-if="notice_rules_dialog.show"
        ></notification-setting>
      </v-dialog>
      <v-dialog v-model="config_dialog" max-width="450">
        <v-card v-if="config_dialog">
          <v-card-title class="text-h6"><v-icon>mdi-cog</v-icon>個人設定</v-card-title>
          <v-card-text>
            <v-form>
              <v-col cols="12">
                <v-select
                  :items="[20, 50, 100]"
                  label="１ページ当たりの表示件数"
                  v-model="config_model.default_page_size"
                  return-object
                  hide-details
                ></v-select>
              </v-col>
              <input style="display: none" />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="onSettingCancel()">キャンセル</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onSettingCommit()">更新</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="white" app> Copyright &copy; CafeReo Holdings Inc. All Rights Reserved. </v-footer>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      <span class="ml-5" style="user-select: none">{{ loadingMessage }}</span>
    </v-overlay>
  </v-app>
</template>

<script>
import NoticeRule from "../../consts/NoticeRule";
import CafereoMenu from "../../components/menu/CafereoMenu.vue";
import FtransMenu from "../../components/menu/FtransMenu.vue";
import MakerMenu from "../../components/menu/MakerMenu.vue";
import VendorMenu from "../../components/menu/VendorMenu.vue";
import UserProfile from "../../components/user/UserProfile.vue";
import PasswordChange from "../../components/user/PasswordChange.vue";
import NotificationSetting from "../../components/user/NotificationSetting.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import moment from "moment";

export default {
  name: "MainPage",
  components: {
    CafereoMenu,
    FtransMenu,
    MakerMenu,
    VendorMenu,
    UserProfile,
    PasswordChange,
    NotificationSetting,
  },
  data: () => ({
    drawer: null,
    notice_menu: false,
    notice_dialog: false,
    selected_notice: {},
    notice_active_tab: null,
    notise_page_size: 5,
    serious_notice_show_page: 1,
    general_notice_show_page: 1,
    profile_dialog: false,
    notice_rules_dialog: { show: false, expand: false },
    notice_rules: NoticeRule,
    enabled_notice_rules: [10101, 10102, 10103, 10201, 10302],
    password_dialog: false,
    config_dialog: false,
    config_model: {
      default_page_size: 100,
    },
    idName: "notification",
    generalSearchStr: null,
    seriousSearchStr: null,
  }),
  created() {
    this.$store.commit("notice/setIntervalId", setInterval(this.readedNotice, process.env.VUE_APP_NOTICE_INTERVAL));
  },
  beforeMount() {
    this.config_model.default_page_size = this.globalPageSize;
    localStorage.removeItem(this.idName);
    this.onNoticeButtonClick();
    this.setNoticeNum();
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    loadingMessage() {
      return this.$store.getters["ui/loadingMessage"];
    },
    username() {
      return this.$store.getters["security/displayname"];
    },
    loggedInUser() {
      return this.$store.getters["security/loggedInUser"];
    },
    notices() {
      let noticeOrg = this.$store.getters["notice/latest"];
      let notices = JSON.parse(JSON.stringify(noticeOrg));
      if (this.generalSearchStr) {
        notices.generalNotifications = noticeOrg.generalNotifications.filter((e) => {
          return e.subtitle.includes(this.generalSearchStr) || e.contents.includes(this.generalSearchStr);
        });
      }
      if (this.seriousSearchStr) {
        notices.seriousNotifications = noticeOrg.seriousNotifications.filter((e) => {
          return e.subtitle.includes(this.seriousSearchStr) || e.contents.includes(this.seriousSearchStr);
        });
      }
      return notices;
    },
    noticenum() {
      return this.$store.getters["notice/getNum"];
    },
    showGeneralNotifications() {
      const start = this.notise_page_size * (this.general_notice_show_page - 1);
      let generalNotifications = [];

      if (Object.keys(this.notices).length > 0) {
        generalNotifications = this.notices.generalNotifications.slice(start, start + this.notise_page_size);
      }
      return generalNotifications;
    },
    showSeriousNotifications() {
      const start = this.notise_page_size * (this.serious_notice_show_page - 1);
      let seriousNotifications = [];
      if (Object.keys(this.notices).length > 0) {
        seriousNotifications = this.notices.seriousNotifications.slice(start, start + this.notise_page_size);
      }
      return seriousNotifications;
    },
    hasprevgeneralnotice() {
      return this.general_notice_show_page > 1;
    },
    hasprevseriousnotice() {
      return this.serious_notice_show_page > 1;
    },
    hasnextgeneralnotice() {
      let showNum;
      if (Object.keys(this.notices).length > 0) {
        showNum = this.general_notice_show_page <= this.notices.generalNotifications.length / this.notise_page_size;
      }
      return showNum;
    },
    hasnextseriousnotice() {
      let showNum;
      if (Object.keys(this.notices).length > 0) {
        showNum = this.serious_notice_show_page <= this.notices.seriousNotifications.length / this.notise_page_size;
      }
      return showNum;
    },
  },
  methods: {
    onSeriousNoticeClick(index) {
      this.selected_notice = this.showSeriousNotifications[index];
      if (!this.selected_notice.readedFlag) {
        this.$store.commit("notice/mark", this.showSeriousNotifications[index].notificationId);
        //既読情報一時保存
        this.saveNoticeRead(this.showSeriousNotifications[index].notificationId);
      }
      this.notice_dialog = true;
    },
    onGeneralNoticeClick(index) {
      this.selected_notice = this.showGeneralNotifications[index];
      if (!this.selected_notice.readedFlag) {
        this.$store.commit("notice/mark", this.showGeneralNotifications[index].notificationId);
        //既読情報一時保存
        this.saveNoticeRead(this.showGeneralNotifications[index].notificationId);
      }
      this.notice_dialog = true;
    },
    async onNoticeButtonClick() {
      // ローカルストレージに既読通知がない場合のみ取得しにいく（既読通知はマウント時削除）
      if (!Object.prototype.hasOwnProperty.call(localStorage, this.idName)) {
        try {
          this.loadingOn();
          console.log("aaa:", this.$store.getters["notice/latest"]);
          const response = await this.$store.dispatch("notice/getNoticeInfo", null);
          if (ApiStatus.consts.SUCCESS !== response.data?.header.resultCode) {
            return this.redirectError();
          }
        } catch (error) {
          console.error("MainPage::onNoticeButtonClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async setNoticeNum() {
      try {
        return await this.$store.dispatch("notice/getNoticeNum", null);
      } catch (error) {
        console.error("MainPage::setNoticeNum", error);
      }
    },
    onSeriousNoticeReadMarkClick(index) {
      this.$store.commit("notice/calcNum", -1);
      this.$store.commit("notice/mark", this.showSeriousNotifications[index].notificationId);
      //既読情報一時保存
      this.saveNoticeRead(this.showSeriousNotifications[index].notificationId);
    },
    onGeneralNoticeReadMarkClick(index) {
      this.$store.commit("notice/calcNum", -1);
      this.$store.commit("notice/mark", this.showGeneralNotifications[index].notificationId);
      //既読情報一時保存
      this.saveNoticeRead(this.showGeneralNotifications[index].notificationId);
    },
    saveNoticeRead(id) {
      //既読情報一時保存
      const readDatetime = new Date().toISOString();
      let notice = {
        notificationId: id,
        readDatetime: readDatetime,
      };
      let noticies = null;
      // ローカルストレージ内の情報を追加
      if (Object.prototype.hasOwnProperty.call(localStorage, this.idName)) {
        noticies = JSON.parse(localStorage.getItem(this.idName));
      } else {
        noticies = {
          marks: [],
        };
      }
      noticies.marks.push(notice);
      localStorage.setItem(this.idName, JSON.stringify(noticies));
    },
    onNoticeRuleCommit() {
      this.$dialog.notify.info("通知設定が更新されました", { timeout: 2300 });
      this.notice_rules_dialog.show = false;
    },
    onPasswordCommit() {
      this.password_dialog = false;
    },
    onSettingCommit() {
      this.$store.commit("ui/pagesize", this.config_model.default_page_size);
      this.$dialog.notify.info("個人設定が変更されました", { timeout: 2300 });
      this.config_dialog = false;
    },
    async onLogoutClick() {
      const ok = await this.$dialog.confirm({
        title: "",
        text: `ログアウトしますか？`,
      });
      if (ok) {
        this.$store.commit("product/clearOrderRecords");
        this.$store.commit("security/logout");
        this.$store.dispatch("notice/clearNoticeInterval", null);
        this.$router.push({ name: "Login" });
      }
    },
    async readedNotice() {
      const condition = localStorage.getItem(this.idName);
      try {
        if (condition) {
          const response = await this.$store.dispatch("notice/entryReadedNotice", JSON.parse(condition));
          if (ApiStatus.consts.SUCCESS === response.data?.header.resultCode) {
            localStorage.removeItem(this.idName);
          }
        }
        await this.setNoticeNum();
      } catch (error) {
        console.error("MainPage::readedNotice", error);
      }
    },
    formatDate(strDate) {
      let ret = "";
      if (strDate) {
        let momentdate = moment(strDate);
        ret = momentdate.format("YYYY/MM/DD") + "\n" + momentdate.format("HH:mm");
      }
      return ret;
    },
    onNoticeRulesExpand(value) {
      this.notice_rules_dialog.expand = value;
    },
    onNoticeMenuClose() {
      this.readedNotice();
      this.notice_menu = false;
    },
    onSettingCancel() {
      this.config_model.default_page_size = this.globalPageSize;
      this.config_dialog = false;
    },
    async onReadedAll(notifications) {
      console.log("onReadedAll notifications");
      console.log(notifications);
      let cnt = 0;
      for (let e of notifications) {
        if (!e.readedFlag) {
          this.$store.commit("notice/calcNum", -1);
          this.$store.commit("notice/mark", e.notificationId);
          //既読情報一時保存
          this.saveNoticeRead(e.notificationId);
          cnt++;
          if (cnt % 500 == 0) {
            await this.readedNotice();
          }
        }
      }
    },
  },
};
</script>
