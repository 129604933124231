import axios from "axios";
import FileUtils from "./../../utils/FileUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async imageUpload(_, param) {
    let filesIdx = 0;
    console.log("imageUpload:::");
    console.log(param);
    const filesArray = Array.from(param.files);
    for (let i = 0; i < param.productImages.length; i++) {
      if (param.productImages[i].url) {
        const url = param.productImages[i].url;
        filesIdx = filesArray.findIndex((e) => e.name === param.productImages[i].name);
        if (filesIdx < 0) {
          filesIdx = i;
          if (param.pushImageStartIdx) {
            filesIdx = filesIdx - param.pushImageStartIdx;
          }
        }
        const buffer = await FileUtils.readAsArrayBuffer(param.files[filesIdx]);
        await axios.put(url, buffer, { headers: { "Content-Type": param.files[filesIdx].type } });
        console.log("url:", url);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
