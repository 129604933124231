<template>
  <v-app>
    <v-main id="main">
      <v-container fluid>
        <v-row justify="center" align-content="center" style="height: 500px">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12 rounded-0" :loading="loading">
              <div class="pa-8">
                <span style="image-rendering: -webkit-optimize-contrast"
                  ><v-img :src="require('../../assets/delphoi_top.png')" width="150"></v-img>
                </span>
                <v-card-title class="h6 font-weight-black">ログイン</v-card-title>
                <v-card-text class="red--text pb-0" v-show="error">{{ error }}</v-card-text>
                <v-card-text>
                  <v-form ref="loginForm" v-model="validLoginForm" lazy-validation>
                    <v-text-field
                      autocomplete="username"
                      label="ログインID"
                      v-model="loginId"
                      prepend-icon="mdi-account-circle"
                      type="email"
                      @keyup.enter="$refs.password.focus()"
                      ref="loginId"
                      :rules="[rules.required, rules.maxLength(128)]"
                    />
                    <v-text-field
                      autocomplete="current-password"
                      label="パスワード"
                      v-model="password"
                      prepend-icon="mdi-key"
                      type="password"
                      @keyup.enter="onLogin"
                      ref="password"
                      :rules="[rules.required]"
                    />
                  </v-form>
                </v-card-text>
                <v-card-text class="py-0">
                  パスワードを忘れた場合は<v-btn text color="blue" :to="{ name: 'ResetPassword' }">こちら</v-btn>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn class="rounded-0" color="primary" :disabled="loginPushCnt > 0" @click="onLogin"
                    >ログイン</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
#main {
  background: linear-gradient(300deg, #ffd244, #f9c2c2);
}
img {
  -webkit-backface-visibility: hidden;
}
</style>

<script>
// import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "Login",
  data() {
    return {
      loginId: "",
      password: "",
      error: "",
      loading: false,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        password: Validation.password,
      },
      validLoginForm: null,
      loginPushCnt: 0,
    };
  },
  created() {
    this.$store.dispatch("notice/clearNoticeInterval", null);
  },
  methods: {
    async onLogin() {
      try {
        this.loginPushCnt++;
        this.loading = true;
        if (this.validate() && this.loginPushCnt === 1) {
          const response = await this.$store.dispatch("security/signin", {
            loginId: this.loginId,
            password: this.password,
          });
          console.log("response:",response);
          if (!response.authenticated) {
            this.error = "有効なログインIDを入力してください";
            return;
          }
          const applicationGroup = response.user.applicationGroup;
          if (this.$route.query.redirect) {
            let redirectPath = this.$route.query.redirect.toString();
            const pathParts = redirectPath.match(/\/(cafereo|supplier|sales|guide-to)(.*)/);
            if (pathParts && pathParts.length > 1) {
              redirectPath = `/${applicationGroup}${pathParts[2] || ""}`;
            }
            this.$router.push(redirectPath);
          } else {
            this.$router.push({ name: "Dashboard", params: { group: applicationGroup } });
          }
        }
      } catch (error) {
        this.error = "有効なログインIDを入力してください";
        console.error("Login::onLogin", error);
        this.password = "";
        this.loginPushCnt = 0;
      } finally {
        this.loading = false;
      }
    },
    validate() {
      const isValid = this.$refs.loginForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        this.loginPushCnt = 0;
      } else {
        this.$refs.loginForm.resetValidation();
      }
      return isValid;
    },
  },
};
</script>
