//import moment from "moment";
import * as Api from "./../../libs/api-client";
import db from "./../../mock/localdb";
import { equals, modifyAll } from "../../mock/mockhelper";
import OrderTypes from "../../consts/OrderTypes";
import RequestUtils from "./../../utils/RequestUtils";
import CorporationTypes from "../../consts/CorporationTypes";

const state = {
  orderRecords: [],
};

const getters = {
  getOrderRecords(state) {
    return state.orderRecords;
  },
  surfaceStatus() {
    return (params, corporateType) => {
      let target = null;
      if (params.data["recordType"] == 1 && params.data["productStatus"] < 5) {
        if (corporateType == CorporationTypes.CAFEREO && params.data["productStatus"] < 5) {
          target = 1;
        } else if (corporateType == CorporationTypes.MAKER && params.data["productStatus"] < 5) {
          target = 1;
        } else if (corporateType == CorporationTypes.VENDOR && params.data["productStatus"] < 3) {
          target = 1;
        }
      }
      if (params.data["isCafereoStock"]) {
        if (corporateType == CorporationTypes.VENDOR && params.data["productStatus"] == 4) {
          target = 2;
        }
      }
      return target;
    };
  },
};
const mutations = {
  addOrderRecords(state, orderRecords) {
    state.orderRecords.push(...orderRecords);
  },
  setOrderRecords(state, orderRecords) {
    state.orderRecords = orderRecords;
  },
  clearOrderRecords(state) {
    state.orderRecords = [];
  },
};
const actions = {
  async search(_, condition) {
    return Api.dispatchGet("/product/search", condition);
  },
  async postSearch(_, condition) {
    return Api.dispatchPost("/product/search", condition);
  },
  async newSearch(_, condition) {
    return Api.dispatchGet("/newproduct/search", condition);
  },
  async history(_, condition) {
    return Api.dispatchGet("/product/history", { jancode: condition });
  },
  async newHistory(_, condition) {
    return Api.dispatchGet("/newproduct/history", { jancode: condition });
  },
  async calcPurchasePrce(_, condition) {
    return Api.commonGet(`/product/calc-purchaseprice`, condition);
  },
  async getImageFileInfo(_, condition) {
    return Api.dispatchGet("/productImage/get-product-images", condition);
  },
  async reGetImageFileInfo(_, condition) {
    return Api.dispatchGet("/product/reget-product-images", condition);
  },
  async isJancode(_, condition) {
    return Api.commonGet("/product/is-jancode", condition);
  },
  async getProductImageTree(_, condition) {
    return Api.dispatchGet("/product/get-product-image-tree", condition);
  },
  async searchTitleList(_, condition) {
    return Api.dispatchGet("/product/search-title-list", condition);
  },
  async entry(_, productModel) {
    console.log(productModel);
    return Api.dispatchPost("/product/create", productModel);
  },
  async update(_, productModel) {
    console.log("/product/update", productModel);
    return Api.dispatchPost("/product/update", productModel);
  },
  async bulkupdate(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/product/bulkupdate", bulkModel, header);
    });
    return response;
  },
  async approval(_, productModel) {
    console.log("product/approval", productModel);
    return Api.dispatchPost("/newproduct/approval", productModel);
  },
  async reject(_, productModel) {
    console.log("product/reject", productModel);
    return Api.dispatchPost("/newproduct/reject", productModel);
  },
  async remove(_, productModel) {
    console.log("product/remove", productModel);
    return Api.dispatchPost("/product/delete", productModel);
  },
  async outListAnimate(_, condition) {
    console.log("product/outListAnimate", condition);
    return Api.dispatchPost("/product/out-list-animate", condition);
  },
  async outListYodobashi(_, condition) {
    console.log("product/outListYodobashi", condition);
    return Api.dispatchPost("/product/out-list-yodobashi", condition);
  },
  async outListYodobashiEc(_, condition) {
    console.log("product/outListYodobashiEc", condition);
    return Api.dispatchPost("/product/out-list-yodobashi-ec", condition);
  },
  async outListSofmap(_, condition) {
    console.log("product/outListSofmap", condition);
    return Api.dispatchPost("/product/out-list-sofmap", condition);
  },
  async outListVillagev(_, condition) {
    console.log("product/outListVillagev", condition);
    return Api.dispatchPost("/product/out-list-villagev", condition);
  },
  async outListHands(_, condition) {
    console.log("product/outListHands", condition);
    return Api.dispatchPost("/product/out-list-hands", condition);
  },
  async outListLoft(_, condition) {
    console.log("product/outListLoft", condition);
    return Api.dispatchPost("/product/out-list-loft", condition);
  },
  async outListLoftEc(_, condition) {
    console.log("product/outListLoftEc", condition);
    return Api.dispatchPost("/product/out-list-loft-ec", condition);
  },
  async outList4Vendor(_, condition) {
    console.log("product/outList4Vendor", condition);
    return Api.dispatchPost("/product/out-list", condition);
  },
  async getLink(_, condition) {
    console.log("/productlink/create", condition);
    return Api.dispatchPost("/productlink/create", condition);
  },
  async import(_, condition) {
    console.log("/product/import-products", condition);
    let response = RequestUtils.procBulkData(
      condition,
      (params) => {
        var payloadParam = {
          products: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/product/import-products-bulk-payload", payloadParam);
      },
      (condition) => {
        let bulkModel = condition.contents;
        let header = condition.header;
        return Api.dispatchPost("/product/import-products", bulkModel, header);
      }
    );
    return response;
  },
  async makerImport(_, condition) {
    console.log("/newproduct/import", condition);
    return Api.dispatchPost("/newproduct/import", condition);
  },

  async stock(_, stockModel) {
    console.log("/product/set-stockstatus", stockModel);
    return Api.dispatchPost("/product/set-stockstatus", stockModel);
  },
  async order({ dispatch, rootGetters }, cartModel) {
    cartModel.orders.forEach((o) => {
      o.forecastOrderFlg = Boolean(rootGetters["security/loggedInUser"].allowForecast);
      o.orderType = OrderTypes.PURCHASE;
      o.dedicatedOrderFlg = null;
      o.customerCode = rootGetters["security/loggedInUser"].customerCode;
      o.grossProfit = o.unitPriceTotal - o.purchasePrice * o.orderQuantity;
      o.lowerPriceTotal = o.purchasePrice * o.orderQuantity;
      o.directDeliveryRequestFlg = null;
      o.shipmentPlanDate = null;
      o.salesStaff = null;
      o.salesAssistant = null;
      o.salesTeam = null;
      o.remarks = null;
      o.cafereoRemarks = null;
    });
    return await dispatch("order/entry", cartModel, { root: true });
  },
  async lockRelation(_, { productCode, locked }) {
    modifyAll(db.t_orders, [equals("productCode", productCode)], { productLocked: locked });
    modifyAll(db.t_purchases, [equals("productCode", productCode)], { orderLocked: locked });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
