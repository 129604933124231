import Vue from "vue";
import VueRouter from "vue-router";
import VueStore from "../store/index";
import Login from "../views/common/Login.vue";
import GlobalError from "../views/common/GlobalError.vue";
import Maintenance from "../views/common/Maintenance.vue";
import MainPage from "../views/common/MainPage.vue";
import Dashboard from "../views/common/Dashboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:group(cafereo|supplier|sales|guide-to)",
    component: MainPage,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "products",
        name: "ProductSearch",
        component: () => import(/* webpackChunkName: "products" */ "./../views/products/ProductSearch.vue"),
        meta: {
          group: ["cafereo", "supplier"],
        },
      },
      {
        path: "product-images",
        name: "ProductImageSearch",
        component: () => import(/* webpackChunkName: "products" */ "./../views/products/ProductImageSearch.vue"),
        meta: {
          group: ["supplier"],
        },
      },
      {
        path: "products-registered",
        name: "ProductRegisteredSearch",
        component: () => import(/* webpackChunkName: "products" */ "./../views/products/ProductRegisteredSearch.vue"),
        meta: {
          group: ["cafereo", "sales"],
        },
      },
      {
        path: "products-registered/:linkId",
        name: "ProductRegisteredSearch",
        component: () => import(/* webpackChunkName: "products" */ "./../views/products/ProductRegisteredSearch.vue"),
        props: true,
      },
      {
        path: "products-entry",
        name: "ProductEntry",
        component: () => import(/* webpackChunkName: "products" */ "./../views/products/NewProductEntry.vue"),
        meta: {
          group: ["supplier"],
        },
      },
      {
        path: "product-images-entry",
        name: "ProductImageEntry",
        component: () => import(/* webpackChunkName: "products" */ "./../views/products/ProductImageEntry.vue"),
        meta: {
          group: ["cafereo", "supplier"],
        },
      },
      {
        path: "product-images-registered",
        name: "ProductImageRegisteredSearch",
        component: () => import(/* webpackChunkName: "products" */ "./../views/products/ProductImageSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "propositions",
        name: "PropositionSearch",
        component: () => import(/* webpackChunkName: "propositions" */ "./../views/propositions/PropositionSearch.vue"),
        meta: {
          group: ["cafereo", "supplier"],
        },
      },
      {
        path: "orders",
        name: "OrderSearch",
        component: () => import(/* webpackChunkName: "orders" */ "./../views/orders/OrderSearch.vue"),
        meta: {
          group: ["cafereo", "sales"],
        },
      },
      {
        path: "order-achievements",
        name: "OrderAchievementSearch",
        component: () => import(/* webpackChunkName: "orders" */ "../views/orders/OrderAchievementSearch.vue"),
        meta: {
          group: ["cafereo", "sales"],
        },
      },
      {
        path: "forecasts",
        name: "ForecastSearch",
        component: () => import(/* webpackChunkName: "orders" */ "./../views/orders/ForecastSearch.vue"),
        meta: {
          group: ["cafereo", "sales"],
        },
      },
      {
        path: "order-returns",
        name: "OrderReturnSearch",
        component: () => import(/* webpackChunkName: "orders" */ "./../views/orders/OrderReturnSearch.vue"),
        meta: {
          group: ["cafereo", "sales"],
        },
      },
      {
        path: "temporary-purchases",
        name: "TemporaryPurchaseSearch",
        component: () => import(/* webpackChunkName: "purchases" */ "./../views/purchases/TemporaryPurchaseSearch.vue"),
        meta: {
          group: ["supplier"],
        },
      },
      {
        path: "purchases",
        name: "PurchaseSearch",
        component: () => import(/* webpackChunkName: "purchases" */ "./../views/purchases/PurchaseSearch.vue"),
        meta: {
          group: ["cafereo", "supplier", "guide-to"],
        },
      },
      {
        path: "purchase-completes",
        name: "PurchaseCompleteSearch",
        component: () => import(/* webpackChunkName: "purchases" */ "./../views/purchases/PurchaseSearch.vue"),
      },
      {
        path: "purchase-arrivalplan",
        name: "PurchaseArrivalPlanSearch",
        component: () =>
          import(/* webpackChunkName: "purchases" */ "./../views/purchases/PurchaseArrivalPlanSearch.vue"),
      },
      {
        path: "purchases/entry",
        name: "PurchaseEntry",
        component: () => import(/* webpackChunkName: "purchases" */ "./../views/purchases/PurchaseEntry.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "purchase-returns",
        name: "PurchaseReturnSearch",
        component: () => import(/* webpackChunkName: "purchases" */ "./../views/purchases/PurchaseReturnSearch.vue"),
        meta: {
          group: ["cafereo", "supplier"],
        },
      },
      {
        path: "cafereo-deficiencies",
        name: "CafereoDeficiencySearch",
        component: () => import(/* webpackChunkName: "purchases" */ "../views/purchases/CafereoDeficiencySearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "deficiencies",
        name: "DeficiencySearch",
        component: () => import(/* webpackChunkName: "purchases" */ "./../views/purchases/DeficiencySearch.vue"),
        meta: {
          group: ["supplier"],
        },
      },
      {
        path: "stocks",
        name: "StockSearch",
        component: () => import(/* webpackChunkName: "stocks" */ "../views/stocks/StockSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "insure-fix",
        name: "InsureFix",
        component: () => import(/* webpackChunkName: "stocks" */ "./../views/stocks/InsureFix.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "delivery-schedules",
        name: "DeliveryScheduleSearch",
        component: () => import(/* webpackChunkName: "stocks" */ "./../views/stocks/DeliveryScheduleSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "shipping-instructions",
        name: "ShippingInstructionSearch",
        component: () => import(/* webpackChunkName: "stocks" */ "./../views/stocks/ShippingInstructionSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "arrival-achievements",
        name: "ArrivalAchievementSearch",
        component: () => import(/* webpackChunkName: "stocks" */ "./../views/stocks/ArrivalAchievementSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "arrival-achievements-today",
        name: "ArrivalAchievementTodaySearch",
        component: () => import(/* webpackChunkName: "stocks" */ "./../views/stocks/ArrivalAchievementTodaySearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "shipment-achievements",
        name: "ShipmentAchievementSearch",
        component: () => import(/* webpackChunkName: "stocks" */ "../views/stocks/ShipmentAchievementSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "ft-stock-matching",
        name: "FTStockMatching",
        component: () => import(/* webpackChunkName: "ftrans" */ "../views/stocks/LogisticsStockMatching.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "amazon-reservations",
        name: "AmazonReservationSearch",
        component: () => import(/* webpackChunkName: "amazon" */ "../views/amazon/ReservationSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "amazon-purchase-orders",
        name: "AmazonPurchaseOrderSearch",
        component: () => import(/* webpackChunkName: "amazon" */ "../views/amazon/PurchaseOrderSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "amazon-carton-instruction",
        name: "AmazonCartonInstruction",
        component: () => import(/* webpackChunkName: "amazon" */ "../views/amazon/CartonInstruction.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "amazon-shipping-instruction",
        name: "AmazonShippingInstruction",
        component: () => import(/* webpackChunkName: "amazon" */ "../views/amazon/ShippingInstruction.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "amazon-shipping-notification",
        name: "AmazonShippingNotification",
        component: () => import(/* webpackChunkName: "amazon" */ "../views/amazon/ShippingNotification.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "production-completed-application",
        name: "ProductionCompletedApplication",
        component: () =>
          import(/* webpackChunkName: "yodobashi" */ "./../views/yodobashi/ProductionCompletedApplication.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "loft-cartons",
        name: "LoftCartonSearch",
        component: () => import(/* webpackChunkName: "loft" */ "../views/loft/LoftCartonSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "bills",
        name: "BillingSearch",
        component: () => import(/* webpackChunkName: "bills" */ "./../views/bills/BillingSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "invoices",
        name: "InvoiceSearch",
        component: () => import(/* webpackChunkName: "bills" */ "./../views/bills/InvoiceSearch.vue"),
        meta: {
          group: ["cafereo", "guide-to"],
        },
      },
      {
        path: "payments",
        name: "PaymentSearch",
        component: () => import(/* webpackChunkName: "bills" */ "./../views/bills/PaymentSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "users",
        name: "UserSearch",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/UserSearch.vue"),
      },
      {
        path: "maker-users",
        name: "MakerUserSearch",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/MakerUserSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "vendor-users",
        name: "VendorUserSearch",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/VendorUserSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "conversions",
        name: "ConversionSearch",
        component: () => import(/* webpackChunkName: "customers" */ "../views/customers/ConversionSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "delivery-destinations",
        name: "DeliveryDestinationSearch",
        component: () =>
          import(/* webpackChunkName: "customers" */ "./../views/customers/DeliveryDestinationSearch.vue"),
        meta: {
          group: ["cafereo", "sales"],
        },
      },
      {
        path: "corporations",
        name: "CorporationSearch",
        component: () => import(/* webpackChunkName: "customers" */ "./../views/customers/CorporationSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "suppliers",
        name: "SupplierSearch",
        component: () => import(/* webpackChunkName: "customers" */ "./../views/customers/SupplierSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "suppliers/edit",
        name: "SupplierEdit",
        component: () => import(/* webpackChunkName: "customers" */ "./../views/customers/SupplierEdit.vue"),
        meta: {
          group: ["supplier"],
        },
      },
      {
        path: "customers",
        name: "CustomerSearch",
        component: () => import(/* webpackChunkName: "customers" */ "../views/customers/CustomerSearch.vue"),
        meta: {
          group: ["cafereo", "sales"],
        },
      },
      {
        path: "corporations/edit",
        name: "CorporationEdit",
        component: () => import(/* webpackChunkName: "customers" */ "../views/customers/CorporationEdit.vue"),
        meta: {
          group: ["sales"],
        },
      },
      {
        path: "rateSettings",
        name: "RateSettingSearch",
        component: () => import(/* webpackChunkName: "customers" */ "./../views/customers/RateSettingSearch.vue"),
        meta: {
          group: ["cafereo"],
        },
      },
      {
        path: "",
        redirect: { name: "Dashboard" },
      },
    ],
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import(/* webpackChunkName: "support" */ "./../views/common/ResetPassword.vue"),
  },
  {
    path: "/error",
    name: "GlobalError",
    component: GlobalError,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "*",
    name: "NotFound",
    redirect: { name: "Login" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const allowedActions = VueStore.getters["security/userAllowedAction"];
  if (allowedActions && allowedActions.length > 0) {
    const passable = to.matched.every((route) => {
      const necessaryRoles = route.meta.role;
      if (necessaryRoles == null || necessaryRoles.length === 0) {
        return true;
      }
      return necessaryRoles.some((role) => allowedActions.includes(role));
    });
    if (!passable) {
      console.error(`Forbidden: ${to.name}.`);
      return next({ name: "GlobalError" });
    }
  }

  const passableGroup = to.matched.every((route) => {
    const necessaryGroups = route.meta.group;
    if (necessaryGroups == null || necessaryGroups.length === 0) {
      return true;
    }
    return necessaryGroups.includes(to.params.group);
  });
  if (!passableGroup) {
    console.error(`Forbidden: ${to.name}.`);
    return next({ name: "GlobalError" });
  }

  if (to.matched.some((route) => route.meta.requiredAuth)) {
    if (!VueStore.getters["security/loggedIn"]) {
      const query = {};
      if (to.name !== "Dashboard") {
        query.redirect = to.fullPath;
      }
      return next({ name: "Login", query });
    }
  }
  next();
});

export default router;
